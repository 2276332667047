

<form (submit)="submit()" [formGroup]="form">

  <section class="partner-signup step-partner-wrapper">
    <div class="container-main">
      <div class="row desktop-only title-partner-fixed">
        <div class="col-md-6">
          <h1 class="step-title">Your Choices, Your Control – We'll Miss You.</h1>
          <p class="step-subtitle">
            We understand that partnerships and collaborations may not always be the right fit for everyone.
            If you would like to opt out of our partnership, please follow the steps below to complete your request.
          </p>
        </div>
        <div class="col-md-6"></div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3 mobile-only">
          <h1 class="step-title">Your Choices, Your Control – We'll Miss You.</h1>
          <p class="step-subtitle">
            We understand that partnerships and collaborations may not always be the right fit for everyone.
            If you would like to opt out of our partnership, please follow the steps below to complete your request.
          </p>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required">Clinic Name</label>
                <input type="text" pInputText class="" name="clinic_name"
                       placeholder="Enter clinic name" formControlName="clinic_name">
                <div class="control__error"
                     *ngIf="form.controls['clinic_name'].touched && form.controls['clinic_name'].errors">
                    <span *ngIf="form.controls['clinic_name'].errors.required">
                      <ng-container>Clinic Name</ng-container>&nbsp;
                      <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                    </span>
                  <span *ngIf="form.controls['clinic_name'].errors.minlength"
                        i18n="@@formInputValidationMinLength">This value is too short. It should have {{
                      form.controls['clinic_name'].errors.minlength.requiredLength }} characters or more.
                    </span>
                  <span *ngIf="form.controls['clinic_name'].errors.maxlength"
                        i18n="@@formInputValidationMaxLength">This value is too long. It should be less than{{
                      form.controls['clinic_name'].errors.maxlength.requiredLength }} characters.
                    </span>
                </div>

              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required">Clinic Email</label>
                <input type="text" pInputText formControlName="clinic_email" name="contact_email"
                       i18n-placeholder="@@formInputEmailPlaceholder" placeholder="Please type your email">
                <div class="control__error"
                     *ngIf="form.controls['clinic_email'].touched && form.controls['clinic_email'].errors">
                    <span *ngIf="form.controls['clinic_email'].errors.required">
                      <ng-container i18n="@@formLabelEmail">Email</ng-container>&nbsp;
                      <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                    </span>
                  <span *ngIf="form.controls['clinic_email'].errors.email" i18n="@@formInputValidationEmailFormat">
                      The value should be a valid email.
                    </span>
                </div>
              </div>

            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required">Clinic Phone Number</label>
                <ngx-intl-tel-input [cssClass]="'custom__phone-lib p-inputtext'"
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="false"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.DialCode, SearchCountryField.Name]"
                                    [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryISO" [minlength]="9"
                                    [maxLength]="19" [numberFormat]="PhoneNumberFormat" [phoneValidation]="true" [inputId]="'phone'"
                                    [separateDialCode]="separateDialCode" name="clinic_phone" [formControlName]="'clinic_phone'">
                </ngx-intl-tel-input>

                <div class="control__error"
                     *ngIf="form.controls['clinic_phone'].touched && form.controls['clinic_phone'].errors">
                    <span *ngIf="form.controls['clinic_phone'].errors.required">
                      <ng-container i18n="@@formLabelPhoneNumber">Phone Number</ng-container>&nbsp;
                      <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                    </span>
                  <span *ngIf="form.controls['clinic_phone'].errors?.validatePhoneNumber">
                      <ng-container i18n="@@formInputPhoneNumberFormat">Check Phone Number format</ng-container>
                    </span>
                  <span *ngIf="form.controls['clinic_phone'].errors.minlength" i18n="@@formInputValidationMinLength">
                        This value is too short. It should have {{
                      form.controls['clinic_phone'].errors.minlength.requiredLength }} characters or more.
                    </span>
                  <span *ngIf="form.controls['clinic_phone'].errors.maxlength" i18n="@@formInputValidationMaxLength">
                      This value is too long. It should be less than {{
                      form.controls['clinic_phone'].errors.maxlength.requiredLength }} characters.
                    </span>
                </div>

              </div>
            </div>

            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required">Why do you want to opt-out?</label>
                <textarea pInputTextarea
                          rows="5"
                          cols="30"
                          [autoResize]="true"
                          class="" name="why_opt_out"
                          placeholder="We’d like to know why you want to opt-out" formControlName="why_opt_out">
                  </textarea>
                <div class="control__error"
                     *ngIf="form.controls['why_opt_out'].touched && form.controls['why_opt_out'].errors">
                    <span *ngIf="form.controls['why_opt_out'].errors.required">
                      <ng-container>Why Opt Out</ng-container>&nbsp;
                      <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                    </span>
                  <span *ngIf="form.controls['why_opt_out'].errors.minlength"
                        i18n="@@formInputValidationMinLength">This value is too short. It should have {{
                      form.controls['why_opt_out'].errors.minlength.requiredLength }} characters or more.
                    </span>
                  <span *ngIf="form.controls['why_opt_out'].errors.maxlength"
                        i18n="@@formInputValidationMaxLength">This value is too long. It should be less than{{
                      form.controls['why_opt_out'].errors.maxlength.requiredLength }} characters.
                    </span>
                </div>

              </div>
            </div>
            <div class="col-xs-12 col-lg-12">
              <div class="general-form">
                <label class="required">Tell Us How we Can Improve</label>
                <textarea pInputTextarea
                          rows="5"
                          cols="30"
                          [autoResize]="true"
                          class="" name="how_can_we_improve"
                          placeholder="Any advice for us?" formControlName="how_can_we_improve">
                  </textarea>
                <div class="control__error"
                     *ngIf="form.controls['how_can_we_improve'].touched && form.controls['how_can_we_improve'].errors">
                    <span *ngIf="form.controls['how_can_we_improve'].errors.required">
                      <ng-container>Why Opt Out</ng-container>&nbsp;
                      <ng-container i18n="@@formInputValidationRequired">is required</ng-container>
                    </span>
                  <span *ngIf="form.controls['how_can_we_improve'].errors.minlength"
                        i18n="@@formInputValidationMinLength">This value is too short. It should have {{
                      form.controls['how_can_we_improve'].errors.minlength.requiredLength }} characters or more.
                    </span>
                  <span *ngIf="form.controls['how_can_we_improve'].errors.maxlength"
                        i18n="@@formInputValidationMaxLength">This value is too long. It should be less than{{
                      form.controls['how_can_we_improve'].errors.maxlength.requiredLength }} characters.
                    </span>
                </div>

              </div>
            </div>

          </div>

        </div>

      </div>
    </div>

  </section>
  <div class="clinic-partner-footer">
    <div class="container-main">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6 text-right">
          <button [disabled]="form.invalid || processing" class="min-151 text-center-button wb-100-m" pButton name="confirm"
                  type="submit">Confirm</button>
        </div>
      </div>
    </div>
  </div>

</form>
