<section class="thank-you">
  <img src="assets/icons/sad-face.svg" alt="sad face icon">

  <h1>Termination of Partnership Process Initiated</h1>

  <p>
    We hate goodbyes, but if you change your mind, we will always be here. Please allow up to 3 business days for your request to be processed.
  </p>

  <button (click)="goto()" pButton> Go back to homepage</button>
  <br/><br/>
</section>
