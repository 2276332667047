<section class="general-partner-clinic">
  <ng-container [ngSwitch]="partnershipFormState">

    <ng-container *ngSwitchCase="partnershipFormEnum.OPT_OUT_FORM">
      <app-opt-out-form
        (partnerFormState)="changeFormState(partnershipFormEnum.OPT_OUT_THANK_YOU)"
        [siteName]="siteName">
      </app-opt-out-form>
    </ng-container>

    <ng-container *ngSwitchCase="partnershipFormEnum.OPT_OUT_THANK_YOU">

      <app-opt-out-thank-you
        [siteName]="siteName">
      </app-opt-out-thank-you>

    </ng-container>


  </ng-container>

</section>
