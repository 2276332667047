import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILocation } from '../../../../../core/src/lib/common/common.models';
import { ApiService } from '../../../../../core/src/lib/data/core-api.service';
import { IPartnerSignUp } from './partner.model';
import { IPartnerOptOut } from '@lib/models/partner.model';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  constructor(private api: ApiService) { }

  getCountries = (): Observable<{ data: Array<ILocation>; meta: any }> => this.api.locationCountriesGET();
  getCertificates = (): Observable<{ data: Array<{ id: string; name: string }>; }> => this.api.clinicsCertificatesGET();
  signUp = (model: IPartnerSignUp): Observable<any> => this.api.clinicsSignUpPOST(model);
  optOut = (model: IPartnerOptOut): Observable<any> => this.api.clinicsOptOutPOST(model);
}
