import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicPanelComponent } from './clinic-panel/clinic-panel.component';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { CoreLayoutsModule } from '../layouts/core-layouts.module';
import { SharedPrimeNgModule } from '../shared-prime-ng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CorePipesModule } from '../pipes/core-pipes.module';
import { ForgotPasswordComponent } from './accounts/forgot-password/forgot-password.component';
import { LoginComponent } from './accounts/login/login.component';
import { RegisterComponent } from './accounts/register/register.component';
import { CustomFormFieldComponent } from '../helper/form-helper/custom-form-field/custom-form-field.component';
import { CustomCoreFormControlDirective } from '../directives/custom-core-form-control.directive';
import { AccountsCoreComponent } from './accounts/accounts-core.component';
import { PartnerSignupCoreComponent } from './partner-signup-core/partner-signup-core.component';
import { PartnershipFormsModule } from '../modules/partnership-forms/partnership-forms.module';
import { SharedDirectivesModule } from '@lib/shared-directives/shared-directives.module';
import { CtaButtonComponent } from './cta-button/cta-button.component';
import { PartnerOptOutCoreComponent } from './partner-opt-out-core/partner-opt-out-core.component';


export const CoreComponents = [
    SearchPanelComponent,
    SubscriptionComponent,
    ClinicPanelComponent,
    PartnerSignupCoreComponent,
    CtaButtonComponent,
    PartnerOptOutCoreComponent
];

export const CoreLoginComponents = [
    AccountsCoreComponent,
    ForgotPasswordComponent,
    LoginComponent,
    RegisterComponent,
];

export const Helper = [
    CustomFormFieldComponent
];

export const Directives = [
    CustomCoreFormControlDirective
];

/**
 * CoreComponentsModule is a module that contains a group of layouted components.
 * It may use multiple layouts components that will be use as a group of components/page.
 * For displaying purposes or for adding additional logic to the current page calling it.
 */
@NgModule({
    declarations: [
        ...CoreComponents,
        ...CoreLoginComponents,
        ...Helper,
        ...Directives,
    ],
    imports: [
        SharedPrimeNgModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreLayoutsModule,
        CorePipesModule,
        PartnershipFormsModule,
        SharedDirectivesModule
    ],
    exports: [
        CoreLayoutsModule,
        ...CoreComponents,
        ...CoreLoginComponents,
        ...Helper,
        ...Directives
    ]
})
export class CoreComponentsModule { }
